<template>
  <Popup v-model="showPopup" @close="closeModal" position="bottom" round>
    <div class="country-list">
      <div class="title">{{ $t("xuan-ze-qu-yu-ma") }}</div>
      <div class="search">
        <input
          type="text"
          v-model="keyword"
          :placeholder="$t('qing-shu-ru-yao-sou-suo-de-guo-jia-ming-cheng-huo-qu-yu-ma')"
          @input="searchCountry"
          @change="searchCountry"
        />
      </div>

      <div class="list-outer">
        <div class="group" v-for="(item, i) in showCountryList" :key="i">
          <div
            class="list flex-center-between"
            :class="child.key == phonePre ? 'active' : ''"
            v-for="(child, index) in item.children"
            :key="index"
            @click.stop="chooseCountry(child)"
          >
            <div class="name flex-center">
              <span>{{ getCountryName(child.title) }}</span>
            </div>
            <div class="key">
              {{ child.key }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>
<script>
import { Popup, Search } from 'vant'
export default {
  components: {
    Popup,
    Search
  },
  data() {
    return {
      keyword: '',
      showPopup: false,
      showCountryList: []
    }
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    phonePre: String
  },
  computed: {
    countryList() {
      return this.$store.state.countryList
    }
  },
  watch: {
    showDialog() {
      this.showPopup = this.showDialog
    }
  },
  mounted() {
    this.showCountryList = [].concat(this.countryList)
    this.showPopup = this.showDialog
  },
  methods: {
    closeModal() {
      this.$emit('update:showDialog', false)
    },
    searchCountry() {
      if (this.keyword) {
        this.showCountryList = this.countryList.map((v) => {
          let children = v.children.filter((val) => {
            return (
              val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            )
          })
          if (children.length > 0) {
            return {
              children: children
            }
          } else {
            return {
              children: []
            }
          }
        })
      } else {
        this.showCountryList = [].concat(this.countryList)
      }
    },
    getCountryName(name) {
      if (!name) {
        return ''
      }
      let newName = ''
      newName = name.substring(0, name.indexOf('(') - 1)
      return newName
    },
    chooseCountry(data) {
      this.$emit('update:phonePre', data.key)
      this.$emit('update:showDialog', false)
    }
  }
}
</script>